var sliderRange = {
    init: function () {

        var rangeSliders = document.querySelectorAll('.js-form-range')

        if(!rangeSliders)return;

        for ( var index = 0; index < rangeSliders.length; ++index ) {
            var rangeId = $( rangeSliders[ index ] ).attr( 'id' )
            initRange( rangeId )
        }

        function initRange ( id ) {

            var $range = $( '#' + id + ' .js-range-slider' );
            var inputFrom = $( '#' + id + ' .js-range-from' )
            var inputTo = $( '#' + id + ' .js-range-to' )
            var instance;
            
            var min = $( '#' + id + ' .js-range-slider' ).data( 'min' )
            var max = $( '#' + id + ' .js-range-slider' ).data( 'max' )

            var from = $(inputFrom).prop( "value" )
            var to = $(inputTo).prop( "value" )

            $range.ionRangeSlider( {
                skin: "round",
                min: min,
                max: max,
                from: from,
                to: to,
                onStart: updateInputs,
                onChange: updateInputs,
                onFinish: updateInputs
            } );

            instance = $range.data( "ionRangeSlider" );

            function updateInputs ( data ) {
                from = data.from;
                to = data.to;

                inputFrom.prop( "value", from );
                inputTo.prop( "value", to );
            }

            inputFrom.on( "change", function () {

                var val = $( this ).prop( "value" );

                if ( val < min ) {
                    val = min;
                } else if ( val > to ) {
                    val = to;
                }

                instance.update( {
                    from: val
                } );

                $( this ).prop( "value", val );

            } );

            inputTo.on( "change", function () {
                var val = $( this ).prop( "value" );

                // validate
                if ( val < from ) {
                    val = from;
                } else if ( val > max ) {
                    val = max;
                }

                instance.update( {
                    to: val
                } );

                $( this ).prop( "value", val );
            } );
        }

    }
}