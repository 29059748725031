var sliderFilter = {
	init: function () {

			var sliderFilters = document.querySelectorAll( '.js-slider-filter' )

			if(!sliderFilters)return;

			function initSliderFilder ( slider ) {

				var $sliderFilter = $( slider )
				var $wrapFilter = $sliderFilter.parent();
				var $sliderInner = $sliderFilter.find( '.js-slider-filter-inner' )
				var $filterSliderScrollbar = $wrapFilter.find( '.js-slider-scrollbar' )

				$sliderFilter.sly( {
					horizontal: 1,
					itemNav: "basic",
					smart: 1,
					activateOn: "click",
					mouseDragging: 1,
					touchDragging: 1,
					releaseSwing: 1,
					scrollSource: $sliderFilter,
					scrollBar: $filterSliderScrollbar,
					scrollBy: 1,
					scrollHijack: 100,
					scrollTrap: true,
					activatePageOn: "click",
					speed: 300,
					easing: "linear",
					dragHandle: 1,
					dynamicHandle: 1,
					clickBar: 1
				} );

				$( window ).resize( function ( e ) {
					$sliderFilter.sly( 'reload' );
					if(getInnerWidth() >= $sliderFilter.width() && screenWidth.mobile() ){
						$filterSliderScrollbar.show()
					}else{
						$filterSliderScrollbar.hide()
					}
				} );

				function getInnerWidth(){
					var innerWidth = 0;

					$sliderInner.children().each( function () {
						innerWidth += $( this ).outerWidth(true);
					} );
					return innerWidth;
				}
			}

			for ( var index = 0; index < sliderFilters.length; ++index ) {
				initSliderFilder( sliderFilters[ index ] )
			}
		
	}
}
