var overlay = {
    init: function () {

        var overlayToggles = document.querySelectorAll( '.js-toggle-overlay' )
        var body = document.body;

        if ( !overlayToggles ) {
            return;
        }

        for ( var index = 0; index < overlayToggles.length; ++index ) {

            overlayToggles[index].onclick = function(e){
                var overlayId = e.target.dataset.overlay;
                var overlay = document.querySelector( '.js-overlay[data-overlay="' + overlayId +'"' )
                
                overlay.classList.toggle( 'active' );
                body.classList.toggle( 'no-scroll' );

            }
        }
    }
}