var sliderThumbs = {
	init: function () {

			var thumbSliders = document.querySelectorAll('.js-slider-wrapper')
			
			if(!thumbSliders)return;
			
			function initThumbnailSlider(slider){

				var $thumbs = $(slider).find('.js-slider-thumbs' )
				var $thumbsWrap = $thumbs.parent();
	
				$thumbs.sly( {
					horizontal: 1,
					itemNav: "basic",
					smart: 1,
					activateOn: "click",
					mouseDragging: 1,
					touchDragging: 1,
					releaseSwing: 1,
					activatePageOn: "click",
					speed: 300,
					easing: "linear",
					dragHandle: 1,
					dynamicHandle: 1,
					clickBar: 1,
					pagesBar: $thumbsWrap.find( '.js-slider-pages' ),
					activatePageOn: 'click',
					pageBuilder: function (index) {
						return '<li></li>';
					},
				} );
	
				$( window ).resize( function ( e ) {
					$thumbs.sly( 'reload' );
				} );
			}


			for(var index = 0; index < thumbSliders.length; ++index){
				initThumbnailSlider(thumbSliders[index])
			}

		
	}
}