var checkout = {
    init: function () {
        var checkoutCounter = document.querySelector( '.js-checkout-counter' );
        var checkoutCounterButton = document.querySelector( '.js-checkout-counter-button' );
        var checkoutInCart = document.querySelector( '.js-checkout-incart' );

        if ( !checkoutCounter || !checkoutCounterButton || !checkoutInCart ) return;

        checkoutCounterButton.addEventListener( 'click', hideCheckoutCounter )

        function hideCheckoutCounter ( e ) {
            checkoutCounter.classList.toggle( 'hide' );
            showCheckinCart()
        }

        function showCheckinCart () {
            checkoutInCart.classList.add( 'show' );
        }
    }
}