var social = {
    init: function () {

        var socials = document.querySelectorAll( '.js-social' )

        if ( !socials ) return;

        function initSocial ( social ) {

            var socialToggle = social.getElementsByClassName( 'js-social-toggle' )[ 0 ]

            socialToggle.onclick = function ( e ) {
                e.stopPropagation();
                if ( utilities.checkActiveClass( e.target.parentNode.parentNode ) ) {
                    hideAllSocials()
                } else {
                    hideAllSocials()
                    showSocial( social )
                }
            }

            function showSocial ( element ) {
                element.classList.toggle( 'active' );
            }

            document.addEventListener( 'click', documentClickHideSocials )
            document.addEventListener( 'keydown', documentEscHideSocials )

            function documentClickHideSocials ( e ) {
                if ( !utilities.checkIfAncestor( e.target, 'js-social' ) ) {
                    hideAllSocials();
                }
            }

            function documentEscHideSocials ( evt ) {
                evt = evt || window.event;
                if ( evt.keyCode == 27 ) {
                    hideAllSocials();
                }
            };

            function hideAllSocials () {
                if ( !socials ) return
                for ( var i = 0; i < socials.length; i++ ) {
                    if ( utilities.checkActiveClass( socials[ i ] ) ) {
                        socials[ i ].classList.remove( 'active' );
                    }
                }
            }
        }

        for ( var index = 0; index < socials.length; ++index ) {
            initSocial( socials[ index ] )
        }
    }
}