var slider = {
	init: function () {
		if ( $( '.c-slider-slick' ).length > 0 ) {

			$( '[data-fancybox="gallery"]' ).fancybox( {
				afterShow: function ( instance, slide ) {

					let idx = $.fancybox.getInstance().currIndex;

					$( '.c-slider-slick-for' ).slick( 'slickGoTo', idx );

				},
				thumbs: {
					showOnStart: true
				},
				hash: false
			} )

			$( '.c-slider-slick-for' ).slick( {
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows: true,
				asNavFor: '.c-slider-slick-nav',
				dots: false,
				draggable: true,
				lazyLoad: 'progressive',
				infinite: false,
				responsive: [
					{
						breakpoint: 992,
						settings: {
							arrows: false,
							dots: true
						}
					}
				]
			} );

			$( '.c-slider-slick-nav' ).slick( {
				slidesToShow: 8,
				slidesToScroll: 1,
				asNavFor: '.c-slider-slick-for',
				dots: false,
				centerMode: false,
				focusOnSelect: true,
				arrows: false,
				draggable: false,
				adaptiveHeight: false,
				variableWidth: false,
				infinite: false,
			} );

			$( '.c-slider-slick-for' ).on( 'init reInit afterChange', function ( event, slick, currentSlide, nextSlide ) {
				if ( currentSlide <= $( '.c-slider-slick-nav' ).slick.slideCount ) {
					$( '.c-slider-slick-nav' ).slick( 'slickGoTo', currentSlide, 'true' );
					$( '.c-slider-slick-nav .slick-track' ).css( 'transform', 'translate3d(0px, 0px, 0px)' );
				}
			} )
		}
	}
}