var popupHome = {
    init: function () {

        var popup = document.querySelector( '.js-popup-home' )
        var popupClose = document.querySelector( '.js-popup-close' )

        if ( !popup ) return;

        popup.classList.toggle( 'active' );

        if ( !popupHome.checkOverlay() ) {
            popupHome.createOverlay();
        }

        // Close popup

        document.addEventListener( 'keydown', documentEscHidePopUp )
        function documentEscHidePopUp ( evt ) {
            evt = evt || window.event;
            if ( evt.keyCode == 27 ) {
                popupHome.close()
            }
        };

        popupClose.onclick = function ( e ) {
            e.preventDefault();
            popupHome.close()
        }

    },
    kill: function () {
        var popup = document.querySelector( '.js-popup-home' )
        if ( !popup ) return;
        popup.classList.remove( 'active' );

    },
    close: function () {
        var popup = document.querySelector( '.js-popup-home' )
        if ( !popup ) return;
        if ( popupHome.checkOverlay() ) {
            popup.classList.remove( 'active' );
            popupHome.deleteOverlay()
        }
    },
    createOverlay: function () {
        var addOverlayDiv = document.createElement( "div" );
        addOverlayDiv.classList.add( 'c-popup__overlay', 'js-popup-home-overlay' )
        document.body.appendChild( addOverlayDiv );
    },
    checkOverlay: function () {
        var overlay = document.querySelectorAll( '.js-popup-home-overlay' )
        if ( overlay.length > 0 ) return true;
    },
    deleteOverlay: function () {
        if ( popupHome.checkOverlay() ) {
            var deleteOverlayDiv = document.querySelector( '.js-popup-home-overlay' )
            document.body.removeChild( deleteOverlayDiv )
        }
    }
}