var counter = {
    init: function () {

        var counters = document.querySelectorAll( '.js-counter' );

        if ( !counters ) {
            return
        }

        function initCounters ( counter ) {

            var counterAmount = counter.getElementsByClassName( 'js-counter-amount' )[ 0 ];
            var plusBtn = counter.getElementsByClassName( 'js-counter-plus' )[ 0 ];
            var minBtn = counter.getElementsByClassName( 'js-counter-min' )[ 0 ];

            var event = document.createEvent("Event");
            event.initEvent("change", false, true); 
 
            plusBtn.onclick = function ( e ) {
                e.preventDefault();
                var currentValue = Number( counterAmount.value );
                counterAmount.value = currentValue + 1

                var event = document.createEvent("Event");
                event.initEvent("change", false, true); 
                counterAmount.dispatchEvent(event);
            }

            minBtn.onclick = function ( e ) {
                e.preventDefault();
                var currentValue = Number( counterAmount.value );
                counterAmount.value = Math.max( 0, currentValue - 1 );

                var event = document.createEvent("Event");
                event.initEvent("change", false, true); 
                counterAmount.dispatchEvent(event);
            }
        }

        for ( var index = 0; index < counters.length; ++index ) {
            initCounters( counters[ index ] )
        }
    },
}