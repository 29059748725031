var accordion = {
    init: function () {
        $( '.js-accordion' ).click( function ( e ) {
            e.preventDefault();
            let $this = $( this );
            
            if ( $this.hasClass( 'js-accordion-disabled' ) ) {
                return;
            }

            if ( $this.next().hasClass( 'active' ) ) {
                $this.next().removeClass( 'active' );
                $this.removeClass( 'active' );
                $this.next().slideUp( 350 );
            } else {
                $( '.js-accordion-content' ).removeClass( 'active' );
                $( '.js-accordion' ).removeClass( 'active' );
                $( '.js-accordion-content' ).slideUp( 350 );
                $this.next().toggleClass( 'active' );
                $this.toggleClass( 'active' );
                $this.next().slideToggle( 350 );
            }
        } );
    },
    trigger: function ( title ) {
        var $this = $( title );

        if ( $this.hasClass( 'active' ) ) {
            return;
        }

        if ( $this.next().hasClass( 'active' ) ) {
            $this.next().removeClass( 'active' );
            $this.removeClass( 'active' );
            $this.next().hide();
        } else {
            $( '.js-accordion-content' ).removeClass( 'active' );
            $( '.js-accordion' ).removeClass( 'active' );
            $( '.js-accordion-content' ).hide();
            $this.next().toggleClass( 'active' );
            $this.toggleClass( 'active' );
            $this.next().show();
        }

    }
}