window.onload = function(){
    accordion.init();
    backtotop.init();
    brands.init();
    checkout.init();
    collapse.init();
    counter.init(); 
    dropdown.init();
    facetToggle.init();
    fancybox.init();
    list.init();
    navDesktop.init();
    navMobile.init();
    navMobile.dropdowns();
    overlay.init();
    popup.init();
    popupHome.init();
    slider.init();
    sliderFilter.init();
    sliderRange.init();
    sliderThumbs.init();
    social.init();
    tabs.init(); 
} 