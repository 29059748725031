var list = {
    init: function () {

        var listTriggers = document.querySelectorAll( '.js-list-trigger' )
        var listCon = document.querySelector( '.js-list-con' )
        var listPro = document.querySelector( '.js-list-pro' )

        if ( !listTriggers || !listCon || !listPro ) return;

        list.setDeleteListeners()

        function createListItem ( e ) {

            e.preventDefault();

            var input = e.target.previousElementSibling.value;
            var list = e.target.parentNode.previousElementSibling

            var listItem = document.createElement( 'LI' );
            var listSpan = document.createElement( 'SPAN' );

            var listInput = document.createElement( 'INPUT' );
            listInput.setAttribute( 'type', 'hidden' );

            var listButton = document.createElement( 'A' );

            if ( input != '' ) {


                e.target.previousElementSibling.value = '';

                listInput.value = input;
                listSpan.innerHTML = input

                listButton.setAttribute( 'href', '#' );
                listButton.classList.add( 'c-rating__delete' )
                listButton.classList.add( 'js-list-delete' )

                list.append( listItem )

                listItem.append( listInput )
                listItem.append( listSpan )
                listItem.append( listButton )

                updateListItem()
            }
        }

        function updateListItem () {

            listProItems = listPro.querySelectorAll( 'li' )
            listConItems = listCon.querySelectorAll( 'li' )

            for ( var index = 0; index < listProItems.length; ++index ) {
                var listProItemInput = listProItems[ index ].querySelector( 'input' )
                listProItemInput.setAttribute( 'name', 'pro[' + index + ']' );
            }

            for ( var index = 0; index < listConItems.length; ++index ) {
                var listConItemInput = listConItems[ index ].querySelector( 'input' )
                listConItemInput.setAttribute( 'name', 'con[' + index + ']' );
            }

            list.setDeleteListeners()
        }

        for ( var index = 0; index < listTriggers.length; ++index ) {
            listTriggers[ index ].addEventListener( 'click', createListItem )
        }


    },
    setDeleteListeners: function(){

        var listDeleteBtns = document.querySelectorAll( '.js-list-delete' )
        
        for ( var index = 0; index < listDeleteBtns.length; ++index ) {
            listDeleteBtns[ index ].addEventListener( 'click', deleteListItem )
        }

        function deleteListItem ( e ) {
            e.preventDefault();
            e.target.parentElement.remove();
        }
    },
}