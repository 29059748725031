var navMobile = {
    init: function () {

        var navMobileToggle = document.querySelector( '.js-mnav-toggle' )
        var navMobileContent = document.querySelector( '.js-mnav' )

        if ( !navMobileToggle || !navMobileContent ) return

        var body = document.body;

        navMobileToggle.addEventListener( 'click', toggleNavMobile );

        function toggleNavMobile ( e ) {
            navMobileContent.classList.toggle( 'active' );
            body.classList.toggle( 'no-scroll' );
            body.classList.toggle( 'push' );
        }


    },
    dropdowns: function () {
        // Dropdowns

        var mnavDropdownTriggers = document.querySelectorAll( '.js-mnav-dropdown-trigger' )
        if(!mnavDropdownTriggers)return;

        function initMnavDropdown(mnavDropdownTrigger){

            mnavDropdownTrigger.addEventListener('click', showDropdownMenu)

            function showDropdownMenu(e){
                e.preventDefault();

                if(utilities.checkOpenClass(e.target)){
                    hideAllDropdownMenus(e);
                }else{
                    hideAllDropdownMenus(e);
                    e.target.classList.add('open');
                    e.target.nextElementSibling.classList.add('open');
                }
            }
        }

        function hideAllDropdownMenus(){
            console.log("hide")
            var dropdownMenus = document.querySelectorAll('.js-mnav-dropdown-menu')
            for ( var index = 0; index < dropdownMenus.length; ++index ) {
                dropdownMenus[index].previousElementSibling.classList.remove('open')
                dropdownMenus[index].classList.remove('open');
            }
        }

        for ( var index = 0; index < mnavDropdownTriggers.length; ++index ) {
            initMnavDropdown( mnavDropdownTriggers[ index ] )
        }
    }
}