var brands = {
    init: function () {
        var brandToggles = document.querySelectorAll( '.js-brand' )

        if ( !brandToggles ) return;

        function initBrandToggle ( brandToggle ) {
            brandToggle.onclick = function ( e ) {
                var id = e.target.getAttribute( "href" ).split( '#' )[ 1 ];
                var brandAccordion = document.getElementById( id );
                var brandAccordionTitle = brandAccordion.querySelector( '.js-accordion' )
                accordion.trigger(brandAccordionTitle)
            }
        }

        for ( var index = 0; index < brandToggles.length; ++index ) {
            initBrandToggle( brandToggles[ index ] )
        }

    }
}