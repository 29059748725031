let tabs = {
    init: function () {

        var tabs = document.querySelectorAll( '.c-tabs' );

        if(!tabs)return;

        function initTabs ( tab ) {

            var jsButtons = tab.getElementsByClassName( 'js-tab-button' );
            var tabContents = tab.getElementsByClassName( 'js-tab-content' );

            function initJsButtons ( jsButton ) {

                jsButton.onclick = function ( e ) {
                    for ( var index = 0; index < jsButtons.length; ++index ) {
                        var el = jsButtons[ index ];
                        el.classList.remove( 'active' )
                    }
                    for ( var index = 0; index < tabContents.length; ++index ) {
                        var el = tabContents[ index ];
                        el.classList.remove( 'active' )
                        if ( el.dataset.tab == e.target.dataset.tab ) {
                            el.classList.add( 'active' )
                        }
                    }
                    e.target.classList.add( 'active' )
                }
            }

            for ( var index = 0; index < jsButtons.length; ++index ) {
                initJsButtons( jsButtons[ index ] )
            }
        }

        for ( var index = 0; index < tabs.length; ++index ) {
            initTabs( tabs[ index ] );
        }
    }
}