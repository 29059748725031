var popup = {
    init: function () {

        var popupToggles = document.querySelectorAll( '.js-popup-toggle' )
        var popupTogglesClose = document.querySelectorAll( '.js-popup-close' )
        var popups = document.querySelectorAll( '.js-popup' )

        if ( !popupToggles || !popups || !popupTogglesClose ) return;

        // Open popup

        function initPopupToggle ( popupToggle ) {
            popupToggle.onclick = function ( e ) {
                e.preventDefault();
                closePopUp()
                var popupId = e.target.getAttribute( "href" ).substring( 1 );
                var popup = document.getElementById( popupId );
                popup.classList.toggle( 'active' );
                initOverlay();
            }
        }

        // overlay

        function checkForOverlay () {
            var overlay = document.querySelectorAll( '.js-popup-overlay' )
            if ( overlay.length > 0 ) return true;
        }

        function initOverlay () {
            if ( !checkForOverlay() ) {
                addOverlay();
            }
        }

        function addOverlay () {
            var addOverlayDiv = document.createElement( "div" );
            addOverlayDiv.classList.add( 'c-popup__overlay', 'js-popup-overlay' )
            document.body.appendChild( addOverlayDiv );
        }

        function deleteOverlayDiv () {
            if ( checkForOverlay() ) {
                var deleteOverlayDiv = document.querySelector( '.js-popup-overlay' )
                document.body.removeChild( deleteOverlayDiv )
            }
        }

        // Close popup
        
        document.addEventListener( 'keydown', documentEscHidePopUp )
         function documentEscHidePopUp ( evt ) {
            evt = evt || window.event;
            if ( evt.keyCode == 27 ) {
                closePopUp()
            }
        };

        function initPopUpClose ( popupToggleClose ) {
            popupToggleClose.onclick = function ( e ) {
                e.preventDefault();
                closePopUp()
            }
        }

        function closePopUp(){
            deleteOverlayDiv()
            for ( var index = 0; index < popups.length; ++index ) {
                popups[ index ].classList.remove( 'active' );
            }
        }

        for ( var index = 0; index < popupToggles.length; ++index ) {
            initPopupToggle( popupToggles[ index ] )
        }

        for ( var index = 0; index < popupTogglesClose.length; ++index ) {
            initPopUpClose( popupTogglesClose[ index ] )
        }
    }
}