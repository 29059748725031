var backtotop = {
    init: function(){
        var btt = document.querySelector('.js-btt')

        if(!btt){
            return;
        }
        
        window.onscroll = function(){
            if(window.pageYOffset >= 150){
                btt.classList.add('active');
            }else{
                btt.classList.remove('active');
            }
        }
    }
}