var preloader = {
    init: function(preloaderDiv){
        var preLoader = document.createElement( "div" );
        preloaderDiv.classList.add('o-relative');
        preLoader.classList.add('c-preloader')
        preLoader.classList.add('js-preloader')
        preLoader.innerHTML = '<svg version="1.1" id="L5" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 60 100" enable-background="new 0 0 0 0" xml:space="preserve"><circle fill="#AE281E" stroke="none" cx="6" cy="50" r="6"><animateTransform attributeName="transform" dur="1s" type="translate" values="0 15 ; 0 -15; 0 15" repeatCount="indefinite" begin="0.1"></animateTransform></circle><circle fill="#AE281E" stroke="none" cx="30" cy="50" r="6"><animateTransform attributeName="transform" dur="1s" type="translate" values="0 10 ; 0 -10; 0 10" repeatCount="indefinite" begin="0.2"></animateTransform></circle><circle fill="#AE281E" stroke="none" cx="54" cy="50" r="6"><animateTransform attributeName="transform" dur="1s" type="translate" values="0 5 ; 0 -5; 0 5" repeatCount="indefinite" begin="0.3"></animateTransform></circle></svg>';
        preloaderDiv.appendChild(preLoader)
    },
    kill: function(preloaderDiv){
        preloaderDiv.classList.remove('o-relative');
        var preLoaderKill = preloaderDiv.querySelector('.js-preloader')
        preloaderDiv.removeChild(preLoaderKill)
    }
}