var facetToggle = {
    init: function () {
        var checkboxLists = document.querySelectorAll( '.js-facet-list' )

        if(!checkboxLists){
            return;
        }

        function initCheckboxList ( checkboxList ) { 

            var toggle = checkboxList.getElementsByClassName( 'js-facet-toggle' )[ 0 ];

            if(!toggle){
                return;
            }
            
            var listItems = checkboxList.getElementsByTagName( 'li' );
            var step = 4;

            toggle.addEventListener( 'click', showAllListItems );

            for ( var index = 0; index < listItems.length; ++index ) {
                if ( index <= step ) {
                    listItems[ index ].style.display = 'block';
                } else {
                    listItems[ index ].style.display = 'none';
                }
            }

            function showAllListItems ( e ) {

                var activeClass = "js-facet-toggle-active"
                e.target.classList.toggle( activeClass );

                if ( e.target.className.indexOf( activeClass ) > -1 ) {
                    for ( var index = 0; index < listItems.length; ++index ) {
                        listItems[ index ].style.display = 'block';
                    }
                } else {

                    for ( var index = 0; index < listItems.length; ++index ) {
                        if ( index <= step ) {
                            listItems[ index ].style.display = 'block';
                        } else {
                            listItems[ index ].style.display = 'none';
                        }
                    }
                }

            }
        }

        for ( var index = 0; index < checkboxLists.length; ++index ) {
            initCheckboxList( checkboxLists[ index ] )
        }
    }
}