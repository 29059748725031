var collapse = {
    init: function () {

        var collapses = document.querySelectorAll( '.js-collapse-wrapper' );

        if(!collapses){
            return;
        }

        function initCollapse ( collapse ) {

            var collapseBtns = collapse.getElementsByClassName( 'js-collapse-btn' );
            var collapseContent = collapse.getElementsByClassName( 'js-collapse-content' )[ 0 ];

            for ( var index = 0; index < collapseBtns.length; ++index ) {

                collapseBtns[ index ].onclick = function ( e ) {
                    e.preventDefault();
                    for ( var index = 0; index < collapseBtns.length; ++index ) {
                        collapseBtns[ index ].classList.toggle( 'active' );
                    }
                    collapseContent.classList.toggle( 'collapsed' );
                }
            }
        }

        for ( var index = 0; index < collapses.length; ++index ) {
            initCollapse( collapses[ index ] );
        }
    }
}