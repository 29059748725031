var utilities = {
    checkActiveClass: function ( element ) {
        var classNames = element.className.split( ' ' );
        if ( classNames.indexOf( 'active' ) !== -1 ) {
            return true;
        }
    },
    checkOpenClass: function ( element ) {
        var classNames = element.className.split( ' ' );
        if ( classNames.indexOf( 'open' ) !== -1 ) {
            return true;
        }
    },
    checkIfAncestor: function ( element, className ) {
        if ( !element || element.length === 0 ) {
            return false;
        }
        var parent = element;
        do {
            if ( parent === document ) {
                break;
            }
            if ( parent.className.indexOf( className ) >= 0 ) {
                return true;
            }
        } while ( parent = parent.parentNode );
        return false;
    },
    fadeIn: function ( element, display, ms ) {
        if ( !element )
            return;

        element.style.opacity = 0;
        element.style.filter = "alpha(opacity=0)";
        element.style.display = display;
        element.style.visibility = "visible";

        if ( ms ) {
            var opacity = 0;
            var timer = setInterval( function () {
                opacity += 50 / ms;
                if ( opacity >= 1 ) {
                    clearInterval( timer );
                    opacity = 1;
                }
                element.style.opacity = opacity;
                element.style.filter = "alpha(opacity=" + opacity * 100 + ")";
            }, 50 );
        }
        else {
            element.style.opacity = 1;
            element.style.filter = "alpha(opacity=1)";
        }
    },
    fadeOut: function ( element, ms ) {
        if ( !element )
            return;

        if ( ms ) {
            var opacity = 1;
            var timer = setInterval( function () {
                opacity -= 50 / ms;
                if ( opacity <= 0 ) {
                    clearInterval( timer );
                    opacity = 0;
                    element.style.display = "none";
                    element.style.visibility = "hidden";
                }
                element.style.opacity = opacity;
                element.style.filter = "alpha(opacity=" + opacity * 100 + ")";
            }, 50 );
        }
        else {
            element.style.opacity = 0;
            element.style.filter = "alpha(opacity=0)";
            element.style.display = "none";
            element.style.visibility = "hidden";
        }
    }
}