var navDesktop = {
    init: function () {

        var navItems = document.querySelectorAll( '.js-nav-item' );

        if(!navItems)return

        function initNavItem ( navItem ) {

            var checkDropdown = navItem.querySelector( '.js-nav-subnav' );
            var openMenuCheck = false;
            var navItemTabs = navItem.querySelectorAll( '.js-tab-button' )

            if ( !!checkDropdown ) {
                navItem.addEventListener( 'mouseover', openMenu )
                navItem.addEventListener( 'mouseleave', collapse )
            }

            function openMenu ( e ) {
                if ( !openMenuCheck ) {
                    if ( navItemTabs.length > 0 ) {
                        resetTabs()
                    }
                    openMenuCheck = true
                    e.target.classList.add( 'current' );
                    navItem.classList.add( 'current' );
                    checkDropdown.classList.add( 'current' );
                    initOverlay()
                }
            }

            function collapse ( event ) {
                var e = event.toElement || event.relatedTarget;

                if ( e.parentNode == this || e == this ) {
                    return;
                }

                event.target.classList.remove( 'current' );
                navItem.classList.remove( 'current' );
                checkDropdown.classList.remove( 'current' );
                deleteOverlayDiv();
                openMenuCheck = false;
            }

            function resetTabs () {
                var jsButtons = navItem.querySelectorAll( '.js-tab-button' );
                var tabContents = navItem.querySelectorAll( '.js-tab-content' );

                for ( var index = 0; index < jsButtons.length; ++index ) {
                    var el = jsButtons[ index ];
                    el.classList.remove( 'active' )
                }
                for ( var index = 0; index < tabContents.length; ++index ) {
                    var el = tabContents[ index ];
                    el.classList.remove( 'active' )
                }

                jsButtons[ 0 ].classList.add( 'active' )
                tabContents[ 0 ].classList.add( 'active' )
            }
        }

        function checkForOverlay () {
            var overlay = document.querySelectorAll( '.js-nav-overlay' )
            if ( overlay.length > 0 ) return true;
        }

        function initOverlay () {
            if ( !checkForOverlay() ) {
                addOverlay();
            }
        }

        function addOverlay () {
            var addOverlayDiv = document.createElement( "div" );
            addOverlayDiv.classList.add( 'p-nav__overlay', 'js-nav-overlay' )
            document.body.appendChild( addOverlayDiv );
        }

        function deleteOverlayDiv () {
            if ( checkForOverlay() ) {
                var deleteOverlayDiv = document.querySelector( '.js-nav-overlay' )
                document.body.removeChild( deleteOverlayDiv )
            }
        }

        for ( var index = 0; index < navItems.length; ++index ) {
            initNavItem( navItems[ index ] )
        }
    }
}